

























import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({

  props: {
    businessId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isWatched: 'profiles/IsWatched',
      isAuthenticated: 'account/IsLoggedIn',
    }),
    isOnWatchlist(): boolean {
      return this.isWatched(this.businessId);
    },
  },
  methods: {
    handleError(e: Error): void {
      this.$toast({
        message: e.message,
        classes: 'is-danger',
        position: 'top',
      });
    },
    async add() {
      this.loading = true;

      await this.$store.dispatch('profiles/WatchlistAdd', {
        BUSINESS_ID: this.businessId,
      }).catch(this.handleError);

      this.loading = false;

      this.$emit('watchlist-added', this.businessId);

      this.$a.goal(this.$a.goals.WatchlistAdd);
    },
    async remove() {
      this.loading = true;

      await this.$store.dispatch('profiles/WatchlistRemove', {
        BUSINESS_ID: this.businessId,
      }).catch(this.handleError);

      this.loading = false;

      this.$emit('watchlist-removed', true);
    },
  },
});
